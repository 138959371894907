<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="notification">
          <h6>👋 Hola! Bienvenido!</h6>
        </div>
      </div>

      <div class="p-col-12 p-pb-0">
        <div class="p-grid">
          <div class="p-col">
            <div class="card overview-box gray">
              <div class="overview-info">
                <h6>Taza del día</h6>
                <h1>{{ referencia }}</h1>
                <h6>{{ fecha }}</h6>
              </div>
              <i class="pi pi-dollar"></i>
            </div>
          </div>
          <div class="p-col">
            <div class="card overview-box blue">
              <div class="overview-info">
                <h6>Envios Nuevos (Hoy)</h6>
                <h1>{{ cnt_envios_nuevos }}</h1>
              </div>
              <i class="pi pi-star"></i>
            </div>
          </div>
          <div class="p-col">
            <div class="card overview-box gray">
              <div class="overview-info">
                <h6>Envios de Ayer</h6>
                <h1>{{ cnt_envios_ayer }}</h1>
              </div>
              <i class="pi pi-inbox"></i>
            </div>
          </div>
          <div class="p-col">
            <div class="card overview-box orange">
              <div class="overview-info">
                <h6>Total de Envios</h6>
                <h1>{{ cnt_envios_total }}</h1>
              </div>
              <i class="pi pi-moon"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-xl-3">
        <div class="card timeline">
          <div class="card-header">
            <div class="card-title">
              <h6>Timeline</h6>
              <p class="subtitle">All servers</p>
            </div>
          </div>
          <ul>
            <li class="blue">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">New Sale</span>
                <span>Richard Jones has purchased a blue t-shirt for $79.</span>
                <span class="time">3 mins ago</span>
              </div>
            </li>
            <li class="blue">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">Log</span>
                <span>AWS settings are updated by admin@freya.com</span>
                <span class="time">12 mins ago</span>
              </div>
            </li>
            <li class="green">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">Capacity</span>
                <span
                  >Reached 80% CPU capacity in Ireland. Automatic capacity
                  increase initiated.</span
                >
                <span class="time">1:30PM</span>
              </div>
            </li>
            <li class="orange">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">Capacity</span>
                <span>Reached 60% CPU capacity in Ireland.</span>
                <span class="time">9:40AM</span>
              </div>
            </li>
            <li class="blue">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">Billing</span>
                <span>Upgraded plan, 10users yearly to 20users yearly</span>
                <span class="time">7:42AM</span>
              </div>
            </li>
            <li class="blue">
              <i class="pi pi-circle-on"></i>
              <div class="event-content">
                <span class="event-title">New Sale</span>
                <span>Richard Jones has purchased a blue t-shirt for $79.</span>
                <span class="time">3 mins ago</span>
              </div>
            </li>
          </ul>
          <a href="#">See all</a>
        </div>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card device-status">
              <div class="p-grid">
                <div class="p-col-12 p-xl-9">
                  <div class="card-header">
                    <div class="card-title">
                      <h6>Total connected device change for last 4 days</h6>
                      <p class="subtitle">Line graph</p>
                    </div>
                  </div>
                  <p class="content">
                    Labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud ercitation ullamco laboris nisi..
                  </p>
                  <div class="progress active">
                    <span>Today</span>
                    <ProgressBar :value="100" :showValue="false"></ProgressBar>
                    <span>2100</span>
                  </div>
                  <div class="progress">
                    <span>Fri</span>
                    <ProgressBar :value="20" :showValue="false"></ProgressBar>
                    <span>1100</span>
                  </div>
                  <div class="progress">
                    <span>Thu</span>
                    <ProgressBar :value="50" :showValue="false"></ProgressBar>
                    <span>1203</span>
                  </div>
                  <div class="progress">
                    <span>Wed</span>
                    <ProgressBar :value="100" :showValue="false"></ProgressBar>
                    <span>1316</span>
                  </div>
                  <a href="#">More</a>
                </div>
                <div class="p-col-12 p-xl-3">
                  <div class="card-header">
                    <div class="card-title">
                      <h6>Devices</h6>
                      <p class="subtitle">Type</p>
                    </div>
                  </div>
                  <div class="p-grid p-nogutter">
                    <div class="p-col-3 p-xl-12">
                      <div class="device">
                        <span><span>1</span> iPhone</span>
                        <span class="status">22%</span>
                      </div>
                    </div>
                    <div class="p-col-3 p-xl-12">
                      <div class="device">
                        <span><span>2</span> Galaxy S20</span>
                        <span class="status">18%</span>
                      </div>
                    </div>
                    <div class="p-col-3 p-xl-12">
                      <div class="device">
                        <span><span>3</span> Macbook</span>
                        <span class="status">13%</span>
                      </div>
                    </div>
                    <div class="p-col-3 p-xl-12">
                      <div class="device">
                        <span><span>4</span> Macbook Air</span>
                        <span class="status">11%</span>
                      </div>
                    </div>
                    <a href="#">See all</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 desktop-teams">
            <div class="card team">
              <div class="card-header">
                <div class="card-title">
                  <h6>Team</h6>
                  <p class="subtitle">22 People</p>
                </div>
              </div>
              <div class="peoples">
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-1.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-2.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-3.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-4.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-5.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-6.png"
                  alt="freya-layout"
                />
                <div class="no-picture" style="background: #bae6ff">
                  <span>AT</span>
                </div>
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-7.png"
                  alt="freya-layout"
                />
                <img
                  src="assets/demo/images/dashboard/avatar/avatar-7.png"
                  alt="freya-layout"
                />
                <div class="no-picture"><span>+18</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-8 p-xl-3">
        <div class="card operations">
          <div class="card-header">
            <div class="card-title">
              <h6>Cloud operations</h6>
              <p class="subtitle">Europe 1</p>
            </div>
            <p class="subtitle">8 May</p>
          </div>
          <Chart
            type="line"
            :data="chart1"
            :options="chartOptions1"
            style="max-height: 160px"
          ></Chart>
          <div class="insights">
            <div class="insight-header">
              <img
                src="assets/demo/images/dashboard/subtract.svg"
                alt="freya-layout"
              />
              <h6>Insights</h6>
            </div>
            <ul>
              <li>
                <span><span>1</span> Increase CPU load levels</span>
                <span class="p-tag p-tag-warning">12%</span>
              </li>
              <li>
                <span><span>2</span> 28% increase on thanks mes./w..</span>
                <span class="p-tag p-tag-success">UP!</span>
              </li>
              <a href="#">See all(4)</a>
            </ul>
          </div>
          <Button
            type="button"
            label="Go to full stock page"
            class="p-button-outlined"
          ></Button>
        </div>
      </div>

      <div class="p-col-12 p-md-4 p-xl-3 mobile-teams">
        <div class="card team">
          <div class="card-header">
            <div class="card-title">
              <h6>Team</h6>
              <p class="subtitle">22 People</p>
            </div>
          </div>
          <div class="peoples">
            <img
              src="assets/demo/images/dashboard/avatar/avatar-1.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-2.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-3.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-4.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-5.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-6.png"
              alt="freya-layout"
            />
            <div class="no-picture" style="background: #ffc3a2">
              <span>TA</span>
            </div>
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-1.png"
              alt="freya-layout"
            />
            <div class="no-picture" style="background: #ffdb7d">
              <span>JH</span>
            </div>
            <img
              src="assets/demo/images/dashboard/avatar/avatar-2.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-3.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-4.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-5.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-6.png"
              alt="freya-layout"
            />
            <div class="no-picture" style="background: #bae6ff">
              <span>SE</span>
            </div>
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-1.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-2.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-3.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-4.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-5.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-6.png"
              alt="freya-layout"
            />
            <div class="no-picture" style="background: #91cc9d">
              <span>TH</span>
            </div>
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <img
              src="assets/demo/images/dashboard/avatar/avatar-7.png"
              alt="freya-layout"
            />
            <div class="no-picture"><span>+18</span></div>
          </div>
          <a href="#">See all teams</a>
        </div>
      </div>

      <div class="p-col-12 p-md-12 p-xl-8">
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card chart">
              <div class="card-header">
                <div class="card-title">
                  <h6>Nasdaq 100</h6>
                  <p class="subtitle">Line graph</p>
                </div>
              </div>
              <Chart
                type="line"
                :data="chart2"
                class="h-30rem"
                :options="chartOptions2"
                style="max-height: 330px"
              ></Chart>
            </div>
          </div>
          <div class="p-col-12 p-md-5">
            <div class="card map">
              <img
                src="assets/demo/images/dashboard/asset-map.png"
                alt="freya-layout"
              />
              <div class="map-content">
                <h6>Last active region (data traffic)</h6>
                <div class="city">
                  <span><span>1</span> Tokyo</span>
                  <span class="status">22%</span>
                </div>
                <div class="city">
                  <span><span>2</span> London</span>
                  <span class="status">22%</span>
                </div>
                <a href="#">See all</a>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-7">
            <div class="card schedule">
              <div class="card-header">
                <div class="card-title">
                  <h6>Schedule</h6>
                  <p class="subtitle">Europe office</p>
                </div>
                <p class="subtitle">Today</p>
              </div>
              <p>3 events, 3h 30m</p>
              <ul>
                <li>
                  <div class="schedule-header">
                    <h6>Daily talks over Zoom w/ teamA</h6>
                    <span>8:00 - 9:00</span>
                  </div>
                  <a href="#">Join over Zoom</a>
                  <span>6 participiants (4 accepted)</span>
                </li>
                <li>
                  <div class="schedule-header">
                    <h6>New Project Kick, v1</h6>
                    <span>8:00 - 9:00</span>
                  </div>
                  <a href="#">Join over Zoom</a>
                  <span>3 participiants (all accepted)</span>
                </li>
                <li>
                  <div class="schedule-header">
                    <h6>Grooming btw TeamA and SR1E</h6>
                    <span>8:00 - 9:00</span>
                  </div>
                  <a href="#">Master room</a>
                  <span>12 participiants (11 accepted)</span>
                </li>
                <li>
                  <div class="schedule-header">
                    <h6>New Project Kick, v2</h6>
                    <span>12:00 - 14:00</span>
                  </div>
                  <a href="#">Master room</a>
                  <span>5 participiants (1 accepted)</span>
                </li>
              </ul>
              <a href="#">Go to full schedule</a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-xl-4">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-xl-12">
            <div class="card statistics">
              <div class="card-header">
                <div class="card-title">
                  <h6>Statistics</h6>
                  <p class="subtitle">Europe 1</p>
                </div>
                <p class="subtitle">8 May</p>
              </div>
              <div class="p-grid">
                <div class="p-col-12 p-md-6">
                  <div class="statistic-item">
                    <div class="item-title">
                      <span>🐳</span>
                      <h5>99,99%</h5>
                    </div>
                    <h6>Uptime/year</h6>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="statistic-item">
                    <div class="item-title">
                      <span>🖥 </span>
                      <h5>2K</h5>
                    </div>
                    <h6>Connected devices</h6>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="statistic-item">
                    <div class="item-title">
                      <span>🎁 </span>
                      <h5>244</h5>
                    </div>
                    <h6>Thanks messages received</h6>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <div class="statistic-item">
                    <div class="item-title">
                      <span>💵 </span>
                      <h5>4812$</h5>
                    </div>
                    <h6>Earnings/month</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-xl-12">
            <div class="card stocks">
              <div class="card-header">
                <div class="card-title">
                  <h6>👓 Stock watchlist (12)</h6>
                  <p class="subtitle">Today</p>
                </div>
                <Button
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-secondary p-button-text p-button-rounded"
                ></Button>
              </div>
              <ul>
                <li class="down">
                  <div class="stock-name">
                    <h6>AMD</h6>
                  </div>
                  <img
                    src="assets/demo/images/dashboard/mini-graph-1.svg"
                    alt="freya-layout"
                  />
                  <div class="stock-price">
                    <i class="pi pi-arrow-down"></i>
                    <h6>54.20</h6>
                  </div>
                  <div class="stock-status">
                    <span>0.57%</span>
                    <span>4.01</span>
                  </div>
                </li>
                <li>
                  <div class="stock-name">
                    <h6>MSFT</h6>
                  </div>
                  <img
                    src="assets/demo/images/dashboard/mini-graph-2.svg"
                    alt="freya-layout"
                  />
                  <div class="stock-price">
                    <i class="pi pi-arrow-down"></i>
                    <h6>183.16</h6>
                  </div>
                  <div class="stock-status">
                    <span>1.46%</span>
                    <span>4.01</span>
                  </div>
                </li>
                <li>
                  <div class="stock-name">
                    <h6>AAPL</h6>
                  </div>
                  <img
                    src="assets/demo/images/dashboard/mini-graph-3.svg"
                    alt="freya-layout"
                  />
                  <div class="stock-price">
                    <i class="pi pi-arrow-down"></i>
                    <h6>307.20</h6>
                  </div>
                  <div class="stock-status">
                    <span>0.59%</span>
                    <span>4.01</span>
                  </div>
                </li>
                <li>
                  <div class="stock-name">
                    <h6>TSLA</h6>
                  </div>
                  <img
                    src="assets/demo/images/dashboard/mini-graph-4.svg"
                    alt="freya-layout"
                  />
                  <div class="stock-price">
                    <i class="pi pi-arrow-down"></i>
                    <h6>799.17</h6>
                  </div>
                  <div class="stock-status">
                    <span>0.52%</span>
                    <span>4.01</span>
                  </div>
                </li>
                <li class="same">
                  <div class="stock-name">
                    <h6>AMZN</h6>
                  </div>
                  <img
                    src="assets/demo/images/dashboard/mini-graph-5.svg"
                    alt="freya-layout"
                  />
                  <div class="stock-price">
                    <i class="pi pi-minus"></i>
                    <h6>799.17</h6>
                  </div>
                  <div class="stock-status">
                    <span>0.52%</span>
                    <span>4.01</span>
                  </div>
                </li>
              </ul>
              <a href="#">Show all</a>
              <Button type="button" label="Go to full stock page"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cnt_envios_nuevos: 0.0,
      cnt_envios_ayer: 0.0,
      cnt_envios_total: 0.0,
      usuario: null,
      fecha: null,
      referencia: null,
      tcambio: null,
      envios_nuevos: 0,
      arr_envios_nuevos: [],
      envios_ayer: 0,
      arr_envios_ayer: [],
      envios_total: 0,
      chart1: {
        labels: ['8Sun', '9Mon', '10Thu', '11Wed', '12Fri', '13Sat', '14Sun'],
        datasets: [
          {
            label: 'Revenue',
            data: [12, 19, 3, 5, 2, 3, 9],
            borderColor: ['#FFA928'],
            borderWidth: 4,
            fill: true,
            backgroundColor: ['rgba(255, 169, 40, .2)'],
            tension: 0.4,
          },
        ],
      },
      chartOptions1: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
      chart2: null,
      chartOptions2: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
        },
        scales: {
          x: {
            display: true,
            grid: {
              color: 'transparent',
            },
            ticks: {
              color: '#BFC2C6',
            },
          },
          y: {
            display: true,
            grid: {
              color: 'rgba(191, 194, 198, .45)',
              borderDash: [5, 10],
            },
            ticks: {
              color: '#BFC2C6',
              min: 0,
              stepSize: 5,
            },
          },
        },
      },
    };
  },
  watch: {
    '$appState.isNewThemeLoaded'(isLoaded) {
      if (isLoaded) {
        this.refreshChart();
        this.$appState.isNewThemeLoaded = false;
      }
    },
  },
  mounted() {
    this.refreshChart();
  },
  created() {
    this.ContadoresV2(true);
    this.tazaCambio(true);
    this.loadDeliveries(true, 1);
    console.log('s');
    if (
      this.$store.getters['listvalues/listvalues']['tazacambio'].length == 0
    ) {
      this.tazaCambio(true);
    } else {
      setTimeout(() => {
        this.tcambio =
          this.$store.getters['listvalues/listvalues']['tazacambio'];

        for (const key in this.tcambio) {
          this.fecha = this.tcambio[key].fecha;
          this.referencia = parseFloat(this.tcambio[key].referencia).toFixed(2);
        }
        setTimeout(() => {
          this.contarDeliveries();
        }, 1500);
      }, 3500);
    }
    setTimeout(() => {
      this.tcambio = this.$store.getters['listvalues/listvalues']['tazacambio'];

      for (const key in this.tcambio) {
        this.fecha = this.tcambio[key].fecha;
        this.referencia = parseFloat(this.tcambio[key].referencia).toFixed(2);
      }
      setTimeout(() => {
        this.contarDeliveries();
      }, 1500);
    }, 3500);
  },
  methods: {
    async ContadoresV2() {
      try {
        const response = await this.$store.dispatch(
          'listvalues/ContadoresEnvios'
        );
        console.log(response);

        console.log(response[0].registrosDiaAnterior);
        console.log(response[0].registrosHoy);
        console.log(response[0].totalRegistros);
        this.cnt_envios_ayer = response[0].registrosDiaAnterior;
        this.cnt_envios_nuevos = response[0].registrosHoy;
        this.cnt_envios_total = response[0].totalRegistros;
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }
      this.isLoading = false;
    },
    async tazaCambio(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/tazaCambio', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    tazaCambios() {
      console.log(this.$store.getters['listvalues/listvalues']['tazacambio']);

      return null;
    },
    async loadDeliveries(refresh = false, cuscia) {
      this.isLoading = true;
      this.deliveries = null;

      try {
        await this.$store.dispatch('deliveries/loadDeliveries', cuscia, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      this.isLoading = false;
      this.deliveries = this.filtrarDeliveries;
      return true;
    },
    refreshChart() {
      this.chart2 = this.getChart();
    },
    getChart() {
      const borderColor =
        getComputedStyle(document.body).getPropertyValue('--primary-color') ||
        '#2c84d8';

      const borderColor2 =
        getComputedStyle(document.body).getPropertyValue('--secondary-color') ||
        '#90EE90';
      const backgroundColor =
        getComputedStyle(document.body).getPropertyValue(
          '--primary-lighter-color'
        ) || '#2c84d8';

      const backgroundColor2 =
        getComputedStyle(document.body).getPropertyValue(
          '--secondary-dark-color'
        ) || '#90EE90';
      return {
        labels: [
          'Ene',
          'Feb',
          'Mar',
          'Abril',
          
        ],
        datasets: [
          {
            label: 'Costos',
            data: [0, 13059.88, 10497.58, 4392.87, ],
            borderColor: [borderColor],
            borderWidth: 4,
            fill: false,
            backgroundColor: [backgroundColor],
            tension: 0.6,
          },
          {
            label: 'Ganancias',
            data: [0, 32549.12, 26229.48, 11167.13,],
            borderColor: [borderColor2],
            //borderWidth: 3,
            fill: false,
            backgroundColor: [backgroundColor2],
            tension: 0.6,
          },
        ],
      };
    },
    contarDeliveries() {
      const contador = null;
      setTimeout(() => {
        const deliveries =
          this.$store.getters['deliveries/deliveries']['deliveries'];
        const tiempoTranscurrido = Date.now();
        console.log(tiempoTranscurrido);
        const hoy = new Date(tiempoTranscurrido);
        console.log(hoy.toISOString().substring(0, 10));
        console.log(hoy.toISOString());
        console.log(hoy.toLocaleString());
        //this.delnit === '' ? 'CF' : this.delnit
        var fecha1 =
          hoy.getFullYear() +
          '-' +
          (hoy.getMonth + 1 < 10
            ? '0' + (hoy.getMonth() + 1)
            : hoy.getMonth() + 1) +
          '-' +
          hoy.getDate();
        fecha1 = hoy.toISOString().substring(0, 10);
        console.log(fecha1);
        var date = new Date(fecha1);
        var yesterday = new Date(date.getTime());
        yesterday.setDate(date.getDate() - 1);

        console.log(yesterday.toISOString().substring(0, 10));
        console.log(yesterday.toISOString().substring(0, 10));

        this.envios_total = deliveries.length;
        this.arr_envios_ayer = [];
        this.arr_envios_nuevos = [];
        for (const key in deliveries) {
          //console.log(deliveries[key].delfecdoc.substring(0, 10));
          //console.log(fecha1);
          if (deliveries[key].delfecdoc.substring(0, 10) == fecha1) {
            console.log('!es de hoy');
            this.arr_envios_nuevos.push(
              deliveries[key].delfecdoc.substring(0, 10)
            );
          } else if (
            deliveries[key].delfecdoc.substring(0, 10) ==
            yesterday.toISOString().substring(0, 10)
          ) {
            console.log(
              'es de ayer ' + yesterday.toISOString().substring(0, 10)
            );
            this.arr_envios_ayer.push(
              deliveries[key].delfecdoc.substring(0, 10)
            );
          }
        }
        this.envios_nuevos = this.arr_envios_nuevos.length;
        this.envios_ayer = this.arr_envios_ayer.length;
      }, 1500);

      return contador;
    },
    contadoresEnviosV2() {
      const contadores =
        this.$store.getters['listvalues/listvalues']['Contadores'];
      console.log(contadores);
    },
  },
  computed: {
    hasTazaCambio() {
      return this.$store.getters['deliveries/hasTazaCambio'];
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-dashboard {
  .orders {
    h4 {
      margin-bottom: 20px;
    }

    .p-button {
      margin-top: -20px;
    }

    .order-tabs {
      margin-bottom: 1rem;

      .order-tab {
        padding: 1rem 2rem 2rem 1rem;
        position: relative;
        transition: box-shadow 0.2s;

        &:hover {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
          cursor: pointer;
        }

        i {
          font-size: 1rem;
          vertical-align: middle;
        }

        .order-label {
          margin-left: 0.25rem;
          vertical-align: middle;
        }

        .stat-detail-icon {
          position: absolute;
          right: 1rem;
          top: 2.25rem;
          height: 1rem;
          width: 1rem;
        }

        img {
          position: absolute;
          bottom: 0;
          left: 5%;
          width: 90%;
        }
      }
    }
  }

  .overview-chart {
    overflow: auto;
  }

  .dashbard-demo-dropdown {
    min-width: 8rem;
    margin-left: auto;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }
}
</style>
